<template>
    <div class="absolute top-0 left-0 z-50 w-full h-full" v-if="! error">
        <Loader
            v-if="initiating"
            text="Wird geladen..."
        />

        <iframe
            v-if="qesConfig"
            ref="iframe"
            :src="qesConfig.url"
            class="w-full h-full mx-auto border-0"
        />
    </div>

    <div v-else class="w-1/2 p-4 mx-auto text-center rounded-md border-red-5 bg-red-9 text-red-5">
        {{ {
            UNEXPECTED: 'Es ist ein Fehler aufgetreten. Sie können diese Seite neu Laden.',
            USER_CANCELED: 'Sie haben den Vorgang abgebrochen.',
            NAME_MATCH: 'Wir konnten Ihre Daten leider nicht überprüfen.',
        }[error] }}
    </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import Loader from "../../components/Common/Loader";
import QESService from "../../services/QESService";

export default {
  components: {
    Loader,
  },
  emits: ["completed"],
  setup(_, { emit }) {
    const iframe = ref(null);
    const error = ref(null);
    const initiating = ref(true);
    const pending = ref(false);
    const store = useStore();

    const qesConfig = computed(() => {
        return store.state.auth.qes;
    });

    const submit = async signatureToken => {
      pending.value = true;

      try {
        await QESService.completeQES(store.state.request.requestId, signatureToken);
        store.commit('auth/setQESSignatureToken', signatureToken);
        emit("completed");
      } catch(err) {
        console.error(err);
      }

      pending.value = false;
    };

    const handler = message => {
        if (message.source !== iframe.value.contentWindow) {
          return;
        }

        switch (message.data.type) {
          case "DOCUMENT_HEIGHT":
            iframe.value.style.height = `${message.data.data.value}px`;
            break;
          case "INITIATED":
            initiating.value = false;
            break;
          case "SIGNATURE_TOKEN":
            submit(message.data.data);
            break;
          case "USER_CANCELED":
            error.value = "USER_CANCELED";
            break;
          case "ERROR":
            if (message.data.data.description == 'IDENTIFICATION_FAILED'){
                error.value = "UNEXPECTED";
            }
            if (message.data.data.description == 'NAME_MATCH'){
                error.value = "NAME_MATCH";
            }
            break;
        }
      };

    onMounted(() => {
      window.addEventListener("message", handler);
    });

    onBeforeUnmount(() => {
        window.removeEventListener("message", handler);
    });

    return {
      error,
      iframe,
      initiating,
      qesConfig,
    };
  },
};
</script>
